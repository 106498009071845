<template>
  <div class="container2">
    <div class="head">
      <h4>找回密码</h4>
    </div>
    <div class="content2">
      <div class="box2" style="width: 500px; margin: 0 auto">
        <div class="content2-box">
          <div class="step">
            <a-steps
              size="small"
              v-model:current="current"
              @change="stepChange"
            >
              <a-step
                v-for="item in steps"
                :key="item.title"
                :title="item.title"
                :status="item.status"
                :disabled="item.disabled"
                :content="item.content"
              />
            </a-steps>
          </div>
          <div class="box-content">
            <a-row v-if="current === 0">
              <a-form
                class="form"
                ref="form"
                :rules="rules"
                :wrapper-col="{ span: 18 }"
                :label-col="{ span: 6 }"
                :model="formData"
                style="width: 70%"
              >
                <a-form-item has-feedback label="手机号:" name="pass">
                  <a-input
                    placeholder="请输入绑定的手机号码"
                    v-model:value="formData.pass"
                    type="password"
                    autocomplete="off"
                  ></a-input>
                </a-form-item>
                <a-row style="width: 100%" type="flex" justify="end">
                  <a-col :span="18">
                    <a-button
                      type="primary"
                      @click="stepTo"
                      style="
                        border-radius: 5px;
                        margin: 20px 0;
                        line-height: 30px;
                        width: 100%;
                      "
                    >
                      确认手机号码
                    </a-button>
                  </a-col>
                </a-row>
              </a-form>
            </a-row>
            <a-row v-if="current === 1">
              <a-form
                class="form"
                ref="form"
                :rules="rules"
                :wrapper-col="{ span: 18 }"
                :label-col="{ span: 6 }"
                :model="formData"
                style="width: 70%"
              >
                <a-form-item has-feedback label="新密码:" name="pass">
                  <a-input
                    placeholder="请输入新密码"
                    v-model:value="formData.pass"
                    type="password"
                    autocomplete="off"
                  ></a-input>
                </a-form-item>
                <a-form-item
                  class="form-item2"
                  label="确认密码:"
                  name="checkPass"
                >
                  <a-input
                    placeholder="请确认新密码"
                    v-model:value="formData.checkPass"
                    type="password"
                    autocomplete="off"
                  ></a-input>
                </a-form-item>
                <a-form-item label="手机:">
                  <a-row class="msg" type="flex" justify="space-between">
                    <div>158****4513</div>
                    <text @click="stepChange((current = 0))">修改</text>
                  </a-row>
                </a-form-item>
                <a-form-item class="form-item2" label="验证:">
                  <a-input
                    placeholder="请输入验证码"
                    style="border-radius: 5px; padding: 5px"
                  >
                    <template #suffix>
                      <smsCode />
                    </template>
                  </a-input>
                </a-form-item>
                <a-row style="width: 100%" type="flex" justify="end">
                  <a-col :span="18">
                    <a-button
                      type="primary"
                      @click="stepTo"
                      style="
                        border-radius: 5px;
                        margin: 10px 0;
                        line-height: 30px;
                        width: 100%;
                      "
                    >
                      确认修改
                    </a-button>
                  </a-col>
                </a-row>
              </a-form>
            </a-row>
            <div v-if="current === 2" style="padding: 0 20px">
              <a-result status="success" title="您已成功重置密码" sub-title="">
                <template #extra>
                  <a  @click="toLogin">点击退出重新登录</a>
                </template>
              </a-result>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-row
      class="breadcrumb"
      type="flex"
      justify="end"
      style="position: absolute; bottom: 20px; left: 20px"
    >
      <a-breadcrumb>
        <a @click="toLogin">
          <HomeOutlined />
          <span>登录</span>
        </a>
      </a-breadcrumb>
    </a-row>
  </div>
</template>
<script>
import validate from '@/utils/validate.js'
import smsCode from '@/components/times'
import { HomeOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    HomeOutlined,
    smsCode
  },
  data() {
    const validatePass = async (rule, value, callback) => {
      if (value === '') {
        return Promise.reject(new Error('Please input the password'))
      } else {
        if (this.formData.checkPass !== '') {
          this.$refs.form.validateFields('checkPass')
        }
        return Promise.resolve()
      }
    }
    const validatePass2 = async (rule, value, callback) => {
      if (value === '') {
        return Promise.reject(new Error('Please input the password again'))
      } else if (value !== this.formData.pass) {
        return Promise.reject(new Error("Two inputs don't match!"))
      } else {
        return Promise.resolve()
      }
    }
    return {
      userphone: '13612345678',
      steps: [
        {
          title: '确认账号',
          content: '确认账号1',
          status: 'process'
        },
        {
          title: '重置密码',
          content: '重置密码2',
          status: 'wait',
          disabled: true
        },
        {
          title: '重置成功',
          content: '重置成功3',
          status: 'wait',
          disabled: true
        }
      ],
      current: 0,
      rules: {
        pass: [{ required: true, validator: validatePass, trigger: 'change' }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'change' }
        ]
      },
      formData: { pass: '', checkPass: '' }
    }
  },
  methods: {
    stepChange(current) {
      const that = this
      let i = 0
      if (current >= this.steps.length - 1) {
        for (i; i < this.steps.length; i++) {
          that.steps[i].status = 'finish'
          that.steps[i].disabled = true
        }
        return
      }
      if (current === 0) {
        i = 1
        that.steps[current].status = 'process'
        for (i; i < this.steps.length; i++) {
          that.steps[i].status = 'wait'
        }
      } else {
        i = current - 1
        that.steps[current].status = 'process'
        for (i; i >= 0; i--) {
          that.steps[i].status = 'finish'
        }
        i = current + 1
        for (i; i < this.steps.length; i++) {
          that.steps[i].status = 'wait'
        }
      }
    },
    stepTo() {
      console.log(validate.isPhone(this.userphone))
      console.log(this.userphone)
      if (!validate.isPhone(this.userphone)) {
        alert('请输入正确的手机格式')
        return
      }

      if (this.current < this.steps.length) {
        this.current++
        this.steps[this.current].disabled = false
        this.stepChange(this.current)
      }
    },
    toLogin() {
      this.$emit('toForget', 1)
    }
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.container2 {
  background: #fff;
  width: 600px;
  height: 450px;
  margin: auto auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: relative;
}
.head {
  padding: 22px 0;
  text-align: center;
}
.box-content {
  margin-top: 30px;
}
.content2-box {
  margin-top: 30px;
}
.form {
  margin: 10px auto;
}
.form input {
  padding: 5px;
  border-radius: 5px;
}
.form-item2 {
  margin: 10px 0;
}
a span {
  margin-left: 5px;
}
</style>
