<template>
  <div class="msg">
    <text
      @click="getnum()"
      v-if="!showNum"
      style="font-size: 10px; color: #1890ff"
      >获取验证码</text
    >
    <text v-else style="font-size: 10px; color: #999"
      >重新发送{{ count }}s</text
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      showNum: false,
      count: 5
    }
  },
  methods: {
    getnum() {
      this.showNum = true
      this.count = 5

      this.timer = setInterval(() => {
        this.count--
        if (this.count < 0) {
          clearInterval(this.timer)
          this.showNum = false
        }
      }, 1000)
    }
  }
}
</script>
<style >
.msg text {
  color: #1890ff;
}
.msg text:hover {
  cursor: pointer;
}
</style>
